import { cloneDeep, concat, flatten, isEmpty, isEqual, set } from 'lodash';
import moment from 'moment';
import config from 'config';
import {
  USAGE_EVENTS,
  clearLocations,
  clearMapData,
  clearOrders,
  clearSolution,
  clearVehicleProfiles,
  recordUsageEvent,
  setError,
  setMapData,
  setOrders,
  setProblem,
  setProblemParameter,
  setSolution,
  setSolutionJson,
  setSolutionParam,
  setTerritoriesFromProblemFile,
  setTerritoryParameter,
  setTourParameter,
  submitProblem,
} from 'actions';
import axios from 'axios';
import { ORDER_ACTIVITIES, ORDER_PRIORITY } from './csv/config';
import { convertProblemToGeoJSON, convertToGeoJSON } from './GeoJSONConverter';
import {
  generateAvoidAreasFromFileProblem,
  generatePolygonsFromOrders,
} from './territories/TerritoriesFromJson';
import { APP_MODES, getFileHost, getFileUrlPath } from './urlHelpers';
import {
  getStopLocation,
  createStopsNoLocation,
  isDepartureOrArrival,
  isBreakOrReload,
} from './SolutionHelpers';
import { isProdEnv } from './helpers';
import { convertCostsToTourPlanner } from './cost';

const {
  defaults: {
    limits: { maxDistance, shiftTime },
  },
} = config;

const addActivityToJobs = (tasks, activityType) => {
  if (!tasks || isEmpty(tasks)) return [];
  return tasks.map((task) => {
    const newTask = cloneDeep(task);
    newTask.activity = activityType;
    return newTask;
  });
};

export const createOrder = (
  location,
  id,
  tag,
  activity,
  priority,
  demand = undefined,
  skills = undefined,
  category = undefined,
  position = undefined,
  groupId = undefined,
  territoryIds = undefined,
) => {
  return {
    Latitude: location.lat,
    Longitude: location.lng,
    Name: `${id} - ${activity}`,
    Address: tag || id,
    Demand: demand,
    ID: id,
    InternalID: id,
    Priority: priority,
    Activity: activity,
    Group: groupId,
    Territories: territoryIds,
    Position: position,
    NameHint: location.nameHint,
    Skills: skills,
    Category: category,
    TimeCreated: Date.now(),
  };
};

export const generateOrdersFromFileProblem = (file) => {
  if (!file || !file.plan) return [];

  const pudoJobs =
    file.plan.groups?.flatMap(({ id: groupId, pudos }) =>
      pudos.flatMap(({ id, places, assignAt }) =>
        places.map(({ location, tag }) =>
          createOrder(
            location,
            id,
            tag,
            ORDER_ACTIVITIES.PUDO,
            ORDER_PRIORITY.NORMAL,
            undefined,
            undefined,
            undefined,
            assignAt,
            groupId,
          ),
        ),
      ),
    ) || [];

  const jobs = file.plan.jobs.flatMap(({ id, tasks, priority, skills, category }) => {
    const deliveries = addActivityToJobs(tasks?.deliveries, ORDER_ACTIVITIES.DELIVERY);
    const pickups = addActivityToJobs(tasks?.pickups, ORDER_ACTIVITIES.PICKUP);
    return [...deliveries, ...pickups]
      .filter((task) => !isEmpty(task))
      .flatMap(({ places, activity, demand, order, position }) =>
        places
          .filter(({ location }) => !isEmpty(location))
          .map(({ location, tag, groupId, territoryIds }) =>
            createOrder(
              location,
              id,
              tag,
              activity,
              priority,
              demand,
              skills,
              category,
              order || position?.value || position?.type,
              groupId,
              territoryIds,
            ),
          ),
      );
  });

  return [...pudoJobs, ...jobs];
};

const activitiesToFilter = ['departure', 'arrival', 'break', 'drivingRestTime', 'workingRestTime'];
export const generateOrdersFromFileSolution = (file, stopsNoLocation) => {
  if (!file || !file.tours || isEmpty(file.tours)) return [];

  const validOrders = concat(
    flatten(
      file.tours.map(({ stops }, tourIndex) =>
        flatten(
          concat(stops).map((stop, stopIndex) =>
            stop.activities.map(({ location: actLocation, jobId, jobTag, type }) => {
              const location =
                actLocation || getStopLocation(stop, stopsNoLocation, stopIndex, tourIndex);
              return createOrder(location, jobId, jobTag, type, ORDER_PRIORITY.NORMAL);
            }),
          ),
        ),
      ),
    ),
    [],
  ).filter((order) => !activitiesToFilter.includes(order.InternalID));

  const unassignedOrders = file.unassigned
    ? file.unassigned.map((job) => ({
        Latitude: 0,
        Longitude: 0,
        Name: job.jobId,
        Address: job.jobId,
        Demand: [0],
        ID: job.jobId,
        InternalID: job.jobId,
        Priority: ORDER_PRIORITY.NORMAL,
      }))
    : [];

  return concat(validOrders, unassignedOrders);
};

export const generateOrdersFromFileJSON = (file, stopsNoLocation = undefined) => {
  if (!file) return [];
  return file.tours && stopsNoLocation
    ? generateOrdersFromFileSolution(file, stopsNoLocation)
    : generateOrdersFromFileProblem(file);
};

export const generateJobsFromSolution = (file) => {
  if (!file || !file.tours || isEmpty(file.tours)) return [];

  const allActivities = file.tours.flatMap((tour) =>
    tour.stops.flatMap((stop) =>
      stop.activities.map((activity) => ({ ...activity, stopLocation: stop.location })),
    ),
  );

  const filteredActivities = allActivities.filter(
    (activity) => !isDepartureOrArrival(activity.jobId) && !isBreakOrReload(activity.jobId),
  );

  const dividedActivities = filteredActivities.reduce(
    ((hash) => (acc, curr) => {
      if (!hash[curr.jobId]) {
        hash[curr.jobId] = [];
        acc.push(hash[curr.jobId]);
      }
      hash[curr.jobId].push(curr);
      return acc;
    })(Object.create(null)),
    [],
  );

  return dividedActivities.map((job) => {
    const jobObject = {
      id: job[0].jobId,
      tasks: {},
    };

    const deliveries = job
      .filter((activity) => activity.type === 'delivery')
      .map((delivery) => {
        const location = delivery.location || delivery.stopLocation;
        return {
          places: [
            {
              duration: 1,
              location,
            },
          ],
          demand: [0],
        };
      });

    const pickups = job
      .filter((activity) => activity.type === 'pickup')
      .map((pickup) => {
        const location = pickup.location || pickup.stopLocation;
        return {
          places: [
            {
              duration: 1,
              location,
            },
          ],
          demand: [0],
        };
      });

    if (deliveries) jobObject.tasks.deliveries = deliveries;
    if (pickups) jobObject.tasks.pickups = pickups;

    return jobObject;
  });
};

export const generateRequestFromSolution = (file) => {
  if (!file) return null;

  return {
    fleet: {},
    plan: {
      jobs: generateJobsFromSolution(file),
    },
  };
};

export const clearAll = (
  dispatch,
  shouldClearOrders,
  shouldClearMap,
  index,
  shouldClearLocations,
) => {
  dispatch(clearSolution());
  dispatch(clearVehicleProfiles(index));
  if (shouldClearLocations) dispatch(clearLocations());
  if (shouldClearOrders) dispatch(clearOrders({ index }));
  if (shouldClearMap) dispatch(clearMapData());
};

export const planTour = ({
  dispatch,
  isSolution,
  cookies,
  user,
  oAuth,
  fileObj,
  stopsNoLocation,
  index,
  keepOrders,
  timesPlanned,
}) => {
  dispatch(setTerritoryParameter({ planned: true, index }));
  dispatch(setSolutionParam({ param: { timesPlanned }, index }));

  if (!isSolution) {
    const apiKey = cookies.apikey;
    dispatch(submitProblem({ json: fileObj, user, oAuth, apiKey, index, keepOrders }));
  } else {
    dispatch(setSolution({ data: fileObj, stopsNoLocation, keepOrders }));
  }
};

export const uploadProblemAndSolution = (
  problem,
  solution,
  mode,
  problemFilename,
  solutionFilename,
  user,
  cookies,
  oAuth,
  dispatch,
  error,
  index,
  keepOrders,
) => {
  dispatch(setSolutionParam({ param: { name: solutionFilename } }));
  dispatch(setSolutionParam({ param: { problemFilename } }));
  dispatch(setSolutionJson({ json: true }));

  const orderList = generateOrdersFromFileJSON(problem);
  if (isEmpty(orderList)) {
    dispatch(setError(error.invalidFileFormat));
    dispatch(setSolutionJson({ jsonUserChange: false, jsonPaste: false }));
    return;
  }

  const stopsNoLocation = createStopsNoLocation(solution.tours);

  const fleet = problem.fleet;
  const vehicleProfiles = fleet.profiles.map((profile) => {
    return {
      name: profile.name,
      avoid: profile.avoid,
      exclude: profile.exclude,
      fleetType: profile.type,
      departureTime: profile.departureTime,
      options: profile.options,
    };
  });
  const vehicles = fleet.types.map(({ shifts, ...item }) => {
    return {
      ...item,
      limits: {
        maxDistance: {
          enabled: item.limits?.maxDistance !== undefined,
          value: item.limits?.maxDistance ?? maxDistance,
        },
        shiftTime: {
          enabled: item.limits?.shiftTime !== undefined,
          value: item.limits?.shiftTime ?? shiftTime,
        },
      },
    };
  });
  const startDate = fleet.types[0].shifts[0].start.time;

  dispatch(
    setTourParameter({
      vehicles,
      vehicleProfiles,
      traffic: fleet.traffic,
      offset: moment.parseZone(startDate).utcOffset(),
    }),
  );
  dispatch(setTourParameter({ fileType: 'json' }));
  dispatch(setOrders(orderList));

  const { territories = [], groupAreas = [] } = generatePolygonsFromOrders(orderList);
  const avoidAreas = generateAvoidAreasFromFileProblem(problem, orderList) || [];
  dispatch(setTerritoriesFromProblemFile({ territories, avoidAreas, groupAreas }));
  const polygons = [...territories, ...avoidAreas, ...groupAreas];

  const geo = convertToGeoJSON(
    solution,
    stopsNoLocation,
    problem.plan.jobs,
    orderList,
    null,
    polygons,
  ).geo;
  if (geo) dispatch(setMapData({ geo }));
  else dispatch(clearMapData());

  const toSet = {
    territories: polygons,
    orders: orderList,
    json: problem,
    problem,
    uploaded: true,
    geo,
    filename: solutionFilename,
  };
  dispatch(setProblem(toSet));

  dispatch(recordUsageEvent({ event: USAGE_EVENTS.ORDERS_ADD, mode }));

  planTour({
    dispatch,
    isSolution: true,
    cookies,
    user,
    oAuth,
    fileObj: solution,
    stopsNoLocation,
    index,
    keepOrders,
  });

  dispatch(setSolutionJson({ jsonUserChange: false, jsonPaste: false }));
};

export const integrateFileText = (
  txt,
  mode,
  filename,
  user,
  cookies,
  oAuth,
  dispatch,
  isJsonViewer,
  error,
  index,
  keepOrders,
  useIndex,
  isDemo,
  startTrans,
  endTrans,
) => {
  let fileObj;
  try {
    fileObj = JSON.parse(txt);
  } catch {
    dispatch(setError(error.invalidFileFormat));
    return;
  }

  const isSolution = !!fileObj.tours;
  const idx = useIndex ? index : undefined;

  if (!isSolution) dispatch(setSolutionJson({ json: true }));

  if (!isSolution && isProdEnv() && (!cookies.apikey || cookies.apikey === '')) {
    dispatch(setError(error.apiKey));
    return;
  }

  let stopsNoLocation;
  if (isSolution) stopsNoLocation = createStopsNoLocation(fileObj.tours);

  const orderList = generateOrdersFromFileJSON(fileObj, stopsNoLocation);
  if (isEmpty(orderList)) {
    dispatch(setError(error.invalidFileFormat));
    dispatch(setSolutionJson({ jsonUserChange: false, jsonPaste: false }));
    return;
  }
  let loc;
  let returnLoc;

  if (!isSolution) {
    const fleet = fileObj.fleet;
    const vehicleProfiles = fleet.profiles.map((profile) => {
      return {
        name: profile.name,
        avoid: profile.avoid,
        exclude: profile.exclude,
        fleetType: profile.type,
        departureTime: profile.departureTime,
        options: profile.options,
      };
    });
    const vehicles = fleet.types.map(({ shifts, ...item }) => {
      return {
        ...item,
        limits: {
          maxDistance: {
            enabled: item.limits?.maxDistance !== undefined,
            value: item.limits?.maxDistance ?? maxDistance,
          },
          shiftTime: {
            enabled: item.limits?.shiftTime !== undefined,
            value: item.limits?.shiftTime ?? shiftTime,
          },
        },
      };
    });
    const startDate = fleet.types[0].shifts[0].start.time;
    const startLoc = fleet.types[0].shifts[0]?.start?.location;
    const endLoc = fleet.types[0].shifts[0]?.end?.location;
    const defaultVal = { label: '', value: null };
    const location =
      startLoc && startLoc.lat && startLoc.lng
        ? { label: startTrans, value: { lat: startLoc.lat, lng: startLoc.lng } }
        : defaultVal;
    loc = location;
    const returnLocation =
      endLoc && endLoc.lat && endLoc.lng
        ? { label: endTrans, value: { lat: endLoc.lat, lng: endLoc.lng } }
        : defaultVal;
    returnLoc = returnLocation;

    dispatch(
      setTourParameter({
        vehicles,
        vehicleProfiles,
        traffic: fleet.traffic,
        offset: moment.parseZone(startDate).utcOffset(),
        index: idx,
        userChange: false,
        location,
        returnLocation,
        returnLocationMode: !isEqual(returnLocation, defaultVal) ? 'end' : 'start',
        uploadedLocations: true,
      }),
    );
  } else {
    dispatch(clearVehicleProfiles(index));
  }
  dispatch(setTourParameter({ fileType: 'json', index: idx }));
  dispatch(setOrders(orderList, isDemo, idx));

  const { territories = [], groupAreas = [] } =
    !isSolution && generatePolygonsFromOrders(orderList);
  const avoidAreas = (!isSolution && generateAvoidAreasFromFileProblem(fileObj, orderList)) || [];
  dispatch(
    setTerritoriesFromProblemFile({
      territories,
      avoidAreas,
      groupAreas,
      index: idx,
    }),
  );
  const polygons = [...territories, ...avoidAreas, ...groupAreas];

  const problemObj = isSolution ? generateRequestFromSolution(fileObj) : fileObj;
  const geo = isSolution
    ? convertToGeoJSON(fileObj, stopsNoLocation, problemObj.plan.jobs, orderList, null, undefined)
        .geo
    : convertProblemToGeoJSON(
        problemObj,
        loc.value,
        returnLoc.value,
        orderList,
        startTrans,
        endTrans,
        null,
        polygons,
      );
  if (geo) dispatch(setMapData({ geo }));
  else dispatch(clearMapData());

  const toSet = {
    territories: polygons,
    orders: orderList,
    json: problemObj,
    fileObj,
    uploaded: true,
    geo,
    filename,
    index: idx,
  };
  dispatch(setProblem(toSet));

  dispatch(recordUsageEvent({ event: USAGE_EVENTS.ORDERS_ADD, mode }));

  if (!isJsonViewer || isSolution) {
    planTour({
      dispatch,
      isSolution,
      cookies,
      user,
      oAuth,
      fileObj,
      stopsNoLocation,
      idx,
      keepOrders,
    });
  }

  dispatch(setSolutionJson({ jsonUserChange: false, jsonPaste: false }));
};

export const fetchUrlFile = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        const fileContent = response.data;

        const urlObject = new URL(url);
        const filename = urlObject.pathname.split('/').pop();

        resolve({ fileContent, filename });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const handleTwoFiles = (files, user, cookies, oAuth, dispatch, error, index) => {
  const file1HasTours = files[0].fileContent.tours;
  const file2HasTours = files[1].fileContent.tours;

  if ((file1HasTours && file2HasTours) || (!file1HasTours && !file2HasTours)) {
    dispatch(setError(error.invalidFileContent));
    return;
  }

  const [problem, sol] = file1HasTours ? [files[1], files[0]] : [files[0], files[1]];
  const problemFile = problem.fileContent;
  const solutionFile = sol.fileContent;
  const typeIds = problemFile.fleet.types.map((type) => type.id);
  const solTypesUsed = solutionFile.tours.map((tour) => tour.typeId);
  const solAndProblemMatch = solTypesUsed.filter((value) => typeIds.includes(value));

  if (solAndProblemMatch.length === 0) {
    dispatch(setError(error.notMatchingProblemAndSol));
    return;
  }

  uploadProblemAndSolution(
    problemFile,
    solutionFile,
    'url',
    problem.filename,
    sol.filename,
    user,
    cookies,
    oAuth,
    dispatch,
    error,
    index,
    false,
  );
};

export const fetchAndProcessFiles = async (
  dispatch,
  oAuth,
  user,
  cookies,
  error,
  solution,
  setShowJsonPanel,
) => {
  const fileUrls = getFileUrlPath();
  if (!oAuth || !fileUrls || fileUrls.length === 0) return;
  if (fileUrls.length > 2) {
    dispatch(setError(error.fileMaximumTwo));
    return;
  }

  const fileHost = getFileHost();
  await Promise.all(fileUrls.map((url) => fetchUrlFile(fileHost ? `${fileHost}/${url}` : url)))
    .then((files) => {
      clearAll(dispatch, false, false, solution.show);
      if (files.length === 1) {
        const { fileContent, filename } = files[0];
        dispatch(setSolutionParam({ param: { name: filename } }));
        integrateFileText(
          JSON.stringify(fileContent, null, 2),
          'url',
          filename,
          user,
          cookies,
          oAuth,
          dispatch,
          true,
          error,
          solution.show,
          false,
        );
      } else if (files.length === 2) {
        handleTwoFiles(files, user, cookies, oAuth, dispatch, error, solution.show);
      }
      setShowJsonPanel(true);
    })
    .catch((err) => {
      if (err.response?.status === 404) dispatch(setError(error.fileNotFound));
      else dispatch(setError(error.unknownError));
    });
};

export const changeJsonOrderLocation = (jsonOrder, order) => {
  if (!jsonOrder || !order) return [];

  const pickup = order.Activity === 'pickup';
  set(
    jsonOrder,
    ['tasks', `${pickup ? 'pickups' : 'deliveries'}`, '0', 'places', '0', 'location'],
    { lat: order.Latitude, lng: order.Longitude },
  );
  return jsonOrder;
};

export const convertOrderToJson = (order) => {
  const pickup = order.Activity === 'pickup';
  const skills = order.Skills || [];
  const tasks = pickup
    ? {
        pickups: [
          {
            places: [
              {
                location: { lat: order.Latitude, lng: order.Longitude },
                duration: 1,
              },
            ],
            demand: order.Demand || [1],
          },
        ],
      }
    : {
        deliveries: [
          {
            places: [
              {
                location: { lat: order.Latitude, lng: order.Longitude },
                duration: 1,
              },
            ],
            demand: order.Demand || [1],
          },
        ],
      };
  return { id: order.InternalID, priority: order.Priority || 5, tasks, skills };
};

export const recalculateMapData = (
  dispatch,
  jsonObject,
  stopsNoLocation,
  territories,
  index,
  orders = undefined,
  startTrans,
  endTrans,
) => {
  const isSolution = !!jsonObject.tours;
  const problemObj = isSolution ? generateRequestFromSolution(jsonObject) : jsonObject;
  const orderList = orders || generateOrdersFromFileJSON(jsonObject, stopsNoLocation);

  const geo = isSolution
    ? convertToGeoJSON(
        jsonObject,
        stopsNoLocation,
        problemObj.plan.jobs,
        orderList,
        null,
        undefined,
      ).geo
    : convertProblemToGeoJSON(
        problemObj,
        null,
        null,
        orderList,
        startTrans,
        endTrans,
        null,
        territories,
      );
  if (geo) {
    dispatch(setMapData({ geo }));
    if (index !== undefined) dispatch(setProblemParameter({ param: { geo }, index }));
  } else {
    dispatch(clearMapData());
  }
  dispatch(setSolutionJson({ jsonUserChange: false }));
};

export const recalculateSolutionParams = (
  jsonObject,
  dispatch,
  error,
  filename,
  stopsNoLocation,
  index,
  isDemo,
  ext,
  isImperial,
  orders,
  startTrans,
  endTrans,
) => {
  const isSolution = !!jsonObject.tours;
  const fileType = ext === 'json' ? ext : 'csv';

  if (!isSolution) {
    const fleet = jsonObject.fleet;
    const vehicleProfiles = fleet.profiles.map((profile) => {
      return {
        name: profile.name,
        avoid: profile.avoid,
        exclude: profile.exclude,
        fleetType: profile.type,
        departureTime: profile.departureTime,
        options: profile.options,
      };
    });

    const vehicles = fleet.types.map(({ shifts, ...item }) => {
      return {
        ...item,
        costs: convertCostsToTourPlanner({ ...item.costs, isImperial }),
        limits: {
          maxDistance: {
            enabled: item.limits?.maxDistance !== undefined,
            value: item.limits?.maxDistance ?? maxDistance,
          },
          shiftTime: {
            enabled: item.limits?.shiftTime !== undefined,
            value: item.limits?.shiftTime ?? shiftTime,
          },
        },
      };
    });
    const startDate = fleet.types[0].shifts[0].start.time;

    dispatch(
      setTourParameter({
        vehicles,
        vehicleProfiles,
        traffic: fleet.traffic,
        offset: moment.parseZone(startDate).utcOffset(),
        jsonUserChange: true,
        index,
      }),
    );
  }
  dispatch(setTourParameter({ fileType, index }));

  const orderList = orders || generateOrdersFromFileJSON(jsonObject, stopsNoLocation);

  if (!isEmpty(orderList)) {
    dispatch(setOrders(orderList, isDemo, index));
  }

  const { territories = [], groupAreas = [] } =
    !isSolution && generatePolygonsFromOrders(orderList);
  const avoidAreas =
    (!isSolution && generateAvoidAreasFromFileProblem(jsonObject, orderList)) || [];
  dispatch(setTerritoriesFromProblemFile({ territories, avoidAreas, groupAreas, index }));
  const polygons = [...territories, ...avoidAreas, ...groupAreas];

  const problemObj = isSolution ? generateRequestFromSolution(jsonObject) : jsonObject;
  const geo = isSolution
    ? convertToGeoJSON(
        jsonObject,
        stopsNoLocation,
        problemObj.plan.jobs,
        orderList,
        null,
        undefined,
      ).geo
    : convertProblemToGeoJSON(
        problemObj,
        null,
        null,
        orderList,
        startTrans,
        endTrans,
        null,
        polygons,
      );

  const toSet = {
    territories: polygons,
    orders: orderList,
    json: problemObj,
    fileObj: jsonObject,
    uploaded: true,
    geo,
    filename,
    index,
  };

  dispatch(setProblem(toSet));
  recalculateMapData(dispatch, jsonObject, stopsNoLocation, polygons, index, orderList);

  dispatch(setSolutionJson({ jsonUserChange: false }));
};

export const readFiles = (files) => {
  return Promise.all(
    files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () =>
          resolve({ fileContent: JSON.parse(reader.result), filename: file.name });
        reader.onerror = reject;
        reader.readAsText(file);
      });
    }),
  );
};

export const handleJsonDrop = (
  dispatch,
  files,
  mode,
  error,
  appMode,
  cookies,
  oAuth,
  user,
  browseEl,
  index,
  keepOrders,
  startTrans,
  endsTrans,
) => {
  if (files.length > 2) {
    dispatch(setError(error.fileMaximumTwo));
    return;
  }
  if (files.length === 2) {
    readFiles([files[0], files[1]])
      .then((jsonFiles) => {
        handleTwoFiles(jsonFiles, user, cookies, oAuth, dispatch, error, index);
      })
      .catch(() => {
        dispatch(setError(error.unableToReadFile));
      });
    return;
  }
  if (appMode.includes(APP_MODES.OCR)) {
    if (!/\.(csv|json|jpg|jpeg|png|gif)$/.test(files[0].name)) {
      dispatch(setError(error.invalidFileExtension));
      return;
    }
  } else if (appMode.includes(APP_MODES.OCR_AWS, APP_MODES.OCR_AWS_EDIT)) {
    if (!/\.(csv|json|jpg|jpeg|png|gif|pdf)$/.test(files[0].name)) {
      dispatch(setError(error.invalidFileExtension));
      return;
    }
  } else if (!/\.(csv|json)$/.test(files[0].name)) {
    dispatch(setError(error.invalidFileExtension));
    return;
  }

  const reader = new FileReader();
  reader.readAsText(files[0]);

  reader.addEventListener('loadend', () => {
    const { result } = reader;
    const filename = files[0].name;

    if (filename.endsWith('.json')) {
      try {
        integrateFileText(
          result,
          mode,
          filename,
          user,
          cookies,
          oAuth,
          dispatch,
          true,
          error,
          index,
          keepOrders,
          false,
          false,
          startTrans,
          endsTrans,
        );
      } catch (err) {
        dispatch(setError(err));
      }

      browseEl.current.value = '';
    }
  });
};
