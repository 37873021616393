import axios from 'axios';
import config from 'config';
import {
  concat,
  filter,
  first,
  flatten,
  includes,
  isEmpty,
  last,
  size,
  some,
  uniqBy,
} from 'lodash';
import { UNASSIGNED_REASONS, setError, setSolutionParam } from 'actions';
import { getSafeValue, setSafeValue } from './security';
import { getAvoidOptions, getExcludeOptions, getVehicleOptions } from './RoutingConverter';
import { compareLocationLatAndLng } from './OrdersHelpers';

const {
  routing: { url: routingUrl },
  splunk: { url: splunkUrl },
} = config;

export const isBreakOrReload = (type) =>
  ['break', 'reload', 'drivingRestTime', 'workingRestTime'].includes(type);

export const isPudo = (type) => type === 'pudo';

export const showIcon = (type) => isBreakOrReload(type) || isPudo(type);

export const isBreak = (type) => ['break', 'drivingRestTime', 'workingRestTime'].includes(type);

export const isReload = (type) => type === 'reload';

export const isDeparture = (type) => type === 'departure';

export const isDepartureOrArrival = (type) => ['departure', 'arrival'].includes(type);

export const isValidIteration = (iteration) =>
  iteration.response && iteration.request && iteration.geoJSON;

export function getLastValidIteration(solution) {
  const valid =
    solution &&
    solution.iterations &&
    solution.iterations.filter((iteration) => isValidIteration(iteration));
  return valid && valid.length > 0 ? getSafeValue(valid, valid.length - 1) : null;
}

export const adjustDownloadGeoJSON = (geo) => {
  if (!geo) return null;

  const jsonGEO = { ...geo };
  delete jsonGEO.bbox;
  delete jsonGEO.unassignedBbox;
  jsonGEO.type = 'FeatureCollection';
  return jsonGEO;
};

export function getSequenceNumbersOfJobs(plannedJobs = []) {
  const result = {};
  plannedJobs.forEach((job, index) => {
    setSafeValue(result, job.id, index);
  });
  return result;
}

export function getStopLocation(stop, stopsNoLocation, stopIndex, tourIndex) {
  if (
    !stop.location &&
    (stop.activities[0].type === 'drivingRestTime' || stop.activities[0].type === 'workingRestTime')
  ) {
    return stopsNoLocation[tourIndex][stopIndex];
  }
  return stop.location;
}

export function getToursWithLocations(tours, stopsNoLocation) {
  return tours.map((tour, tourIndex) => {
    const stops = tour.stops.map((stop, index) => {
      return { ...stop, location: getStopLocation(stop, stopsNoLocation, index, tourIndex) };
    });

    return { ...tour, stops };
  });
}

export const getSplunkAWSLogUrl = (dispatch, request, isProd, index) => {
  const statusId = request?.statusId;
  const asyncStartTime = request?.asyncStartTime;
  const finishTime = request?.runtimeFinishTime;
  const splunkLogUrl = request?.splunkLogUrl;

  if (!isProd && statusId && asyncStartTime && (!splunkLogUrl || finishTime)) {
    const latest = finishTime ? Math.floor(finishTime / 1000) : 'now';
    const url = `${splunkUrl}/search?earliest=${asyncStartTime}&latest=${latest}&q=search index=foapi ${statusId}`;

    dispatch(
      setSolutionParam({
        param: { splunkLogUrl: url },
        index,
      }),
    );

    if (finishTime)
      dispatch(
        setSolutionParam({
          param: { runtimeFinishTime: null, asyncStartTime: null },
          index,
        }),
      );
  }
};

export function createStopsNoLocation(tours) {
  return tours.map((tour) =>
    tour.stops.map((stop, index, original) => {
      if (
        !stop.location &&
        (stop.activities[0].type === 'drivingRestTime' ||
          stop.activities[0].type === 'workingRestTime')
      ) {
        const lastLocation = last(
          original.slice(0, index).filter((el) => el.location !== undefined),
        );
        if (lastLocation) return { ...lastLocation.location };
        return { ...first(original.filter((el) => el.location !== undefined)).location };
      }
      return {};
    }),
  );
}

export function getToursDataFromIteration(iteration) {
  return iteration && iteration.response
    ? {
        ...iteration.response,
        ...iteration.request.json,
        problemUploaded: !!iteration.request.uploaded,
        timestamp: iteration.request.timestamp,
        tours: getToursWithLocations(iteration.response.tours, iteration.stopsNoLocation),
        jobNumbers: iteration.request.json
          ? getSequenceNumbersOfJobs(iteration.request.json.plan.jobs)
          : {},
      }
    : null;
}

export function getVehicleTypeById(typeId, fleet) {
  return !isEmpty(fleet) ? fleet.types.find((type) => type.id === typeId) : null;
}

export function getJobIdsForStops(stops) {
  return stops.reduce(
    (acc, stop) => acc.concat(stop.activities.map((activity) => `${activity.jobId}`)),
    [],
  );
}

export function getDemand({ tour, plan, orders }) {
  const stops = tour?.stops;

  if ((!stops || !plan) && !isEmpty(orders)) {
    const totalDemand = orders
      .filter((o) => !isBreakOrReload(o.Activity) && !isPudo(o.Activity))
      .reduce((acc, o) => {
        o.Demand?.forEach((demand, index) => {
          acc[index] = (acc[index] || 0) + demand;
        });
        return acc;
      }, []);
    return isEmpty(totalDemand) ? null : totalDemand;
  }

  if (!stops || !plan) return null;

  const jobIds = getJobIdsForStops(stops);

  const totalDemand = [];
  const multiJobs = plan.jobs.filter((job) => job.demand === undefined);
  const justJobs = plan.jobs.filter((job) => job.demand !== undefined);

  justJobs.push(
    ...multiJobs.reduce((jobs, multiJob) => {
      const flatJobs = concat(multiJob.tasks.pickups, multiJob.tasks.deliveries)
        .filter((task) => !isEmpty(task))
        .map((task) => ({
          id: multiJob.id,
          tag: task.tag,
          demand: task.demand,
        }));

      return jobs.concat(flatJobs);
    }, []),
  );

  justJobs.forEach((job) => {
    if (includes(jobIds, `${job.id}`) && job.demand.length) {
      job.demand.forEach((demand, index) => {
        setSafeValue(totalDemand, index, getSafeValue(totalDemand, index) || 0);
        setSafeValue(totalDemand, index, getSafeValue(totalDemand, index) + demand);
      });
    }
  });

  return isEmpty(totalDemand) ? null : totalDemand;
}

export function getTourStart(vehicleId, tours) {
  const tour = tours.find((t) => t.vehicleId === vehicleId);
  if (!tour) {
    return undefined;
  }
  const departure = tour.stops.find((s) => s.activities.some((i) => i.jobId === 'departure'));
  return (departure || tour.stops[0]).time.departure;
}

export function getTourEnd(vehicleId, tours) {
  const tour = tours.find((t) => t.vehicleId === vehicleId);
  if (!tour) {
    return undefined;
  }
  const arrival = tour.stops.find((s) => s.activities.some((i) => i.jobId === 'arrival'));
  return (arrival && arrival.time.arrival) || last(tour.stops).time.departure;
}

export const getNumberOfDepotStops = (stops = []) =>
  size(
    filter(stops, ({ activities }) =>
      some(activities, ({ jobId }) => jobId === 'arrival' || jobId === 'departure'),
    ),
  );

export function getLoadPercentage(tourDemand, vehicleType) {
  if (!tourDemand || !vehicleType) return null;

  return vehicleType.capacity.map((item, index) => {
    return item === 0
      ? `${item}%`
      : `${(Math.min((getSafeValue(tourDemand, index) || 0) / item, 1) * 100).toFixed(0)}%`;
  });
}

export function getTotalJobsInStop(stop) {
  return stop.activities.filter((a) => includes(['delivery', 'pickup'], a.type)).length;
}

export function getTotalJobsInTour(stops) {
  return stops.reduce((total, stop) => {
    return total + getTotalJobsInStop(stop);
  }, 0);
}

export function getTotalJobsInTours(tours) {
  return tours && tours.reduce((total, tour) => total + getTotalJobsInTour(tour.stops), 0);
}

export const getUnassignedLocations = (unassignedJobs, tourData, orders) => {
  if (!unassignedJobs || !tourData || orders.length === 0) return [];
  const jobs = tourData.plan.jobs;
  const locArray = [];

  unassignedJobs.forEach((unassigned) => {
    if (unassigned?.loc) unassigned.loc = [];
    orders.forEach((order) => {
      if (order.InternalID === unassigned.jobId || order.Address === unassigned.jobId) {
        if (!unassigned.loc) {
          unassigned.loc = [{ lat: order.Latitude, lng: order.Longitude }];
        } else {
          unassigned.loc = unassigned.loc.concat({
            lat: order.Latitude,
            lng: order.Longitude,
          });
        }
      }
    });
  });

  unassignedJobs.forEach((unassigned) =>
    jobs.forEach((job) => {
      if (
        unassigned.jobId === job.id &&
        unassigned.reasons.some((reason) => UNASSIGNED_REASONS.includes(reason.code))
      ) {
        const deliveries = job.tasks?.deliveries;
        const pickups = job.tasks?.pickups;

        if (deliveries)
          job.tasks?.deliveries.forEach((delivery) =>
            delivery.places.forEach((place) => {
              if (unassigned.loc) {
                unassigned.loc.forEach((loc) => {
                  if (compareLocationLatAndLng(loc, place.location, 4))
                    locArray.push({
                      loc: place.location,
                      id: job.id,
                      duration: place.duration,
                    });
                });
              }
            }),
          );
        if (pickups)
          job.tasks?.pickups.forEach((pickup) =>
            pickup.places.forEach((place) => {
              if (unassigned.loc) {
                unassigned.loc.forEach((loc) => {
                  if (compareLocationLatAndLng(loc, place.location, 4))
                    locArray.push({
                      loc: place.location,
                      id: job.id,
                      duration: place.duration,
                    });
                });
              }
            }),
          );
      }
    }),
  );
  return locArray;
};

export const getUniqueJobNotices = (result, unassignedList) => {
  if (!result || !unassignedList) return [];
  const noticeArray = [];
  result.forEach((res) => {
    const routes = res.data.routes?.[0];
    if (routes)
      routes.sections.forEach((section) =>
        unassignedList.forEach((unassigned) => {
          const arrivalOriginal = section.arrival.place.originalLocation;
          const departureOriginal = section.departure.place.originalLocation;
          const arrival = section.arrival.place.location;
          const departure = section.departure.place.location;
          const unassignedLoc = unassigned.loc;
          if (
            compareLocationLatAndLng(departure, unassignedLoc, 4) ||
            compareLocationLatAndLng(arrival, unassignedLoc, 4) ||
            compareLocationLatAndLng(departureOriginal, unassignedLoc, 4) ||
            compareLocationLatAndLng(arrivalOriginal, unassignedLoc, 4)
          ) {
            const notice = noticeArray.find((n) => n.jobId === unassigned.id);
            if (notice) {
              const notices = notice.notices;
              const sectionNotices = section?.notices;
              if (notices && sectionNotices) notices.push(...sectionNotices);
              else notice.notices = section.notices;
            } else {
              noticeArray.push({ jobId: unassigned.id, notices: section.notices });
            }
          }
        }),
      );
  });

  const filtered = noticeArray.map((notice) => {
    return { ...notice, notices: uniqBy(notice.notices, 'title') };
  });

  return filtered;
};

export async function getUnassignedNotices(locations, oAuth, tourPlanner, originCoords) {
  const traffic = tourPlanner.traffic;

  const authHeaders = oAuth && {
    headers: {
      Authorization: `Bearer ${oAuth.accessToken}`,
    },
  };
  const vehicleProfiles = tourPlanner.vehicleProfiles.map((vp) => {
    return { avoid: vp.avoid, options: vp.options, exclude: vp.exclude, type: vp.fleetType };
  });

  const requests = vehicleProfiles.map((vehicleProfile) => {
    const locationArray = locations.flatMap((location) => {
      const avoid = vehicleProfile?.avoid;
      const options = vehicleProfile?.options;
      const exclude = vehicleProfile?.exclude;
      const mode = vehicleProfile?.type;

      const avoidOpts = avoid && getAvoidOptions(avoid);
      const excludeOpts = exclude && getExcludeOptions(exclude);
      const opts = options && getVehicleOptions(options);
      const excludeOptions =
        excludeOpts &&
        Object.keys(excludeOpts).map((key) => `${key}=${getSafeValue(excludeOpts, key)}`);
      const vehicleOptions =
        opts && Object.keys(opts).map((key) => `${key}=${getSafeValue(opts, key)}`);
      const avoidOptions =
        avoidOpts && Object.keys(avoidOpts).map((key) => `${key}=${getSafeValue(avoidOpts, key)}`);

      const params = [
        `destination=${location.loc.lat},${location.loc.lng}`,
        `origin=${originCoords.lat},${originCoords.lng}`,
        `transportMode=${mode}`,
        'return=polyline',
      ];

      if (avoidOptions) params.push(...avoidOptions);
      if (excludeOptions) params.push(...excludeOptions);
      if (vehicleOptions) params.push(...vehicleOptions);
      if (traffic) params.push(`traffic=${traffic}`);

      const url = `${routingUrl}?${params.join('&')}`;
      const response = axios.get(url, authHeaders);
      return response;
    });

    return locationArray;
  });

  const responses = await axios.all(flatten(requests).map((req) => req));
  const results = getUniqueJobNotices(responses, locations).filter(
    (res) => res.jobId && res.notices?.length > 0,
  );
  return results;
}

export function getTypeCount(tours) {
  if (!tours) return 0;

  return Object.entries(
    tours.reduce(
      (acc, cur) => ({ ...acc, [`${cur.typeId},${cur.vehicleId}`]: (acc[cur.typeId] || 0) + 1 }),
      {},
    ),
  ).map(([name, count]) => ({ name: name.split(',')[0], count, id: name.split(',')[1] }));
}

export function getVehicleTypeCount(tourPlanner, typeCount, fleet, totalAmount) {
  if (!tourPlanner || !typeCount) return null;

  if (isEmpty(fleet)) {
    return typeCount.map((type) => ({
      name: type.id,
      count: type.count,
      amount: totalAmount,
      fleetType: 'car',
    }));
  }

  return tourPlanner.vehicleProfiles
    .flatMap((vp) =>
      tourPlanner.vehicles.flatMap((v) =>
        typeCount.map((v1) => {
          const amount =
            v.id === v1.name
              ? v.amount ?? v1.count
              : v.vehicleIds && v.vehicleIds.includes(v1.id)
              ? v1.count
              : 0;
          return {
            name: v1.id,
            count: v1.count,
            amount,
            fleetType: vp.name === v.profile ? vp.fleetType : '',
          };
        }),
      ),
    )
    .filter((f) => f.amount && f.fleetType);
}

export function isDemandVisible(orders, tourPlanner) {
  if (isEmpty(orders)) return false;
  if (tourPlanner && !isEmpty(tourPlanner.lastDemandLabel)) return true;
  const totalDemand = orders.reduce((t, o) => t + (o.Demand ? o.Demand[0] : 0), 0);
  return totalDemand > 0;
}

export const isIterationSolution = (iteration) => {
  if (!iteration || isEmpty(iteration)) return false;
  return !!(iteration.response && iteration.response.statistic);
};

export const getNumberOfJobs = (jobs) =>
  jobs.reduce((acc, job) => {
    const deliveries = job.tasks.deliveries?.length ?? 0;
    const pickups = job.tasks.pickups?.length ?? 0;
    return acc + deliveries + pickups;
  }, 0);

export const hasOngoingAsyncRequest = (requests) =>
  requests.some((req) => req.statusHref && !req.href);

export const getPredefinedFiles = async (name, setFile, setFileName, dispatch, error) => {
  await axios
    .get(`resources/${name}`)
    .then((res) => {
      setFile(res.data);
      setFileName();
    })
    .catch(() => {
      dispatch(setError(error.contactTrans));
    });
};

export const getAllLocationsInTour = (tour) => {
  const locations = [];

  tour.stops.forEach((stop) => {
    if (
      stop.location &&
      stop.activities.some(
        (activity) => !(isDepartureOrArrival(activity.type) || isBreakOrReload(activity.type)),
      )
    ) {
      locations.push([stop.location.lng, stop.location.lat]);
    }
    stop.activities.forEach((activity) => {
      if (
        activity.location &&
        !isDepartureOrArrival(activity.type) &&
        !isBreakOrReload(activity.type)
      )
        locations.push([activity.location.lng, activity.location.lat]);
    });
  });

  return locations;
};

export const removeProperties = (json) => {
  if (!json.tours) return json;
  return {
    ...json,
    tours: json.tours.map((tour) => ({
      ...tour,
      stops: tour.stops.map((stop) => ({
        ...stop,
        activities: stop.activities.map(
          ({
            departure,
            arrival,
            pickup,
            delivery,
            break: b,
            drivingRestTime,
            workingRestTime,
            reload,
            pudo,
            ...rest
          }) => rest,
        ),
      })),
    })),
  };
};
